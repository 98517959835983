
import { Component, Vue } from "vue-property-decorator";
import { Screen } from "@/store/modules/screen/models";

@Component({
  components: {},
})
export default class App extends Vue {
  window: Screen = { width: 0, height: 0 };

  onMounted() {
    this.$store.dispatch("acessibilidade/loadState");
  }

  onClickContraste() {
    console.log("onClickContraste");
    this.$store.dispatch("acessibilidade/toggleContraste");
  }

  onMaisInfromacoes() {
    this.$router.push("/pagina/informacoes-de-acessibilidade");
  }

  onClickAMais() {
    window.alert('Para ampliar o texto, use as teclas "CTRL" e "+".');
  }

  onClickAMenos() {
    window.alert('Para ampliar o texto, use as teclas "CTRL" e "-".');
  }

  created(): void {
    this.$store.dispatch("acessibilidade/loadState");
    console.log(this.$store.getters["acessibilidade/isContraste"]);

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  destroyed(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(): void {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
    this.$store.dispatch("screen/setSizes", this.window);
    // this.$store.commit('screen/setWidthHeight', this.window);
  }
}
